@charset "UTF-8";
body.iluna {
  background-color: #323a46;
  color: #dee2e6;
}

body.iluna h3 {
  color: #dee2e6;
}

body.iluna .barramenu {
  background-color: #3b434e;
  border-right: solid 1px #4a4e52;
}

body.iluna .card {
  background-color: #3b434e;
  color: #dee2e6;
}

body.iluna .cont-select-maquinas {
  background-color: #3b434e;
}

body.iluna .caja-dentro-tab,
body.iluna .caja-imagen {
  background-color: #323a46;
}

/*form*/
body.iluna .form-control {
  /*   color: $color-texto;
   background-color: $color-input;
  border: 1px solid $color-fondo;*/
}

/*body.iluna input,
body.iluna .k-input,
body.iluna input:hover,
body.iluna input:focus,*/
body.iluna .k-dropdown .k-dropdown-wrap,
body.iluna .k-dropdowntree .k-dropdown-wrap,
body.iluna textarea,
body.iluna textarea:hover,
body.iluna .k-upload,
body.iluna span.k-select,
body.iluna .k-animation-container,
body.iluna .k-numerictextbox .k-numeric-wrap,
body.iluna .k-textbox,
body.iluna .k-textbox:hover,
body.iluna .k-textbox.k-state-hover,
body.iluna .k-combobox > :hover,
body.iluna .k-combobox .k-state-hover,
body.iluna .k-combobox .k-dropdown-wrap {
  background-color: #434b56;
  border: 1px solid #4b5460 !important;
  color: #dee2e6;
  background-image: none;
}

body.iluna .k-numerictextbox .k-select .k-link:hover,
body.iluna .k-numerictextbox .k-select .k-link.k-state-hover,
body.iluna .k-combobox > :hover .k-select,
body.iluna .k-combobox .k-state-hover .k-select {
  background-color: #434b56;
}

body.iluna kendo-multiselect .k-button {
  background-color: #323a46;
}

body.iluna .k-content {
  /*border-color: rgba(0, 0, 0, 0.08);*/
  color: #dee2e6;
  background-color: #434b56 !important;
}

body.iluna .k-calendar-infinite .k-calendar-header {
  background-color: #434b56 !important;
}

body.iluna .k-calendar-infinite .k-calendar-view,
body.iluna .k-calendar-infinite .k-calendar-monthview {
  background-color: #434b56 !important;
  color: #dee2e6 !important;
}

body.iluna .k-multiselect-wrap.k-floatwrap kendo-searchbar.k-searchbar input.k-input {
  border-color: #434b56 !important;
}

body.iluna .k-multiselect .k-multiselect-wrap::before,
body.iluna .k-dropdowntree .k-multiselect-wrap::before {
  color: #dee2e6;
}

body.iluna .k-button, body.iluna .k-button-secondary {
  color: #dee2e6 !important;
  /*background-color: $color-input !important;*/
  border-width: 0 !important;
}

body.iluna .k-dropdown-wrap > .k-input, body.iluna .k-dropdown .k-dropdown-wrap .k-select, body.iluna .k-list, body.iluna .k-reset {
  background-color: #434b56 !important;
  color: #dee2e6;
}

body.iluna ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dee2e6;
}

body.iluna :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dee2e6;
}

body.iluna ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dee2e6;
}

body.iluna kendo-datepicker.form-control.k-widget.k-datepicker,
body.iluna kendo-dropdownlist.form-control {
  background: transparent;
  border: 0;
}

body.iluna .form-control:focus {
  color: #dee2e6;
}

body.iluna .k-multiselect .k-multiselect-wrap,
body.iluna .k-dropdowntree .k-multiselect-wrap {
  background-color: #434b56;
}

body.iluna .k-upload .k-dropzone .k-upload-status,
body.iluna .k-upload .k-dropzone .k-dropzone-hint {
  color: #dee2e6;
}

body.iluna .form-control {
  /*  background-color: transparent;*/
}

body.iluna .k-button.k-upload-button {
  background: #3b434e;
  color: #dee2e6;
  margin: 0 13px 0 0 !important;
}

body.iluna .k-combobox.k-combobox-clearable .k-input::placeholder {
  color: #dee2e6;
  opacity: 0.5;
}

body.iluna .k-i-close::before {
  color: #dee2e6;
}

body.iluna .k-switch-off .k-switch-container:hover {
  background-color: #323a46;
}

body.iluna .k-switch-off .k-switch-container {
  background-color: #434b56;
}

body.iluna .k-switch-on .k-switch-handle,
body.iluna .k-switch-on:hover .k-switch-handle,
body.iluna .k-switch-on.k-state-hover .k-switch-handle,
body.iluna .k-switch-off .k-switch-handle,
body.iluna .k-switch-off .k-switch-handle:hover {
  background-color: #323a46;
  border-color: #434b56;
}

/*form amaiera*/
/*tabs*/
body.iluna .k-tabstrip-items .k-state-active .k-link {
  color: #dee2e6;
}

body.iluna .k-tabstrip-items .k-state-active .k-link {
  background-color: #3b434e;
}

body.iluna .k-tabstrip-items .k-item {
  background-color: #2e343e;
  border-left: 1px solid #323a46;
  border-top: 1px solid #323a46;
}

/*tabs*/
/*pop up*/
body.iluna .modal-content {
  background-color: #3b434e;
}

body.iluna .close {
  color: #dee2e6;
}

/*pop up*/
body.iluna .caja-geometria label {
  color: #dee2e6;
}

body.iluna .caja-geometria {
  background-color: #434b56;
}

body.iluna .k-numerictextbox {
  background-color: #434b56;
}

/*GRID*/
body.iluna .k-grid {
  background-color: #3b434e;
  color: #dee2e6;
}

body.iluna span.k-icon.k-i-filter,
body.iluna .k-grid th.k-header.k-filterable span.k-link {
  color: #dee2e6;
}

body.iluna .k-popup.k-grid-filter-popup {
  background-color: #3b434e;
}

body.iluna .k-grid tbody tr:hover,
body.iluna .k-grid tbody tr.k-state-hover,
body.iluna .k-grid-header .k-grid-filter:hover,
body.iluna .k-grid-header .k-header-column-menu:hover,
body.iluna .k-grid-header .k-hierarchy-cell .k-icon:hover {
  color: #dee2e6;
  background-color: #434b56;
}

body.iluna .k-grid-content {
  background-color: transparent;
}

/*scroll bereziak grid*/
body.iluna .k-grid-content::-webkit-scrollbar-track {
  background: #434b56;
}

/*scroll bereziak grid amaiera*/
/*GRID amaiera*/
/*Gráficos*/
body.iluna tspan {
  fill: #dee2e6;
}

body.iluna .c3 line, body.iluna .c3 path {
  stroke: #dee2e6;
}

/*Gráficos amaiera*/
body.iluna kendo-grid-toolbar.k-toolbar.k-grid-toolbar {
  background: #3b434e;
}

body.iluna table.table-vertical th,
body.iluna table.no-vertical th {
  background-color: #3b434e;
  border: 3px solid #323a46;
}

body.iluna table.table-vertical td,
body.iluna table.no-vertical td {
  background-color: #434b56;
  border: 3px solid #323a46;
}

body.iluna th.header-negrita.destacado {
  color: #424242;
  background: #ffffff;
}