@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
body, html {
  height: 100%;
  overflow: auto;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  /*position: relative;
  padding-bottom: 50px;*/
}

.footer {
  bottom: 0;
  height: 2em;
  left: 0;
  position: relative;
  line-height: 22px;
}

/*orokorrak*/
label {
  font-size: 13px;
  margin-bottom: 0;
}

.card-header {
  background-color: initial;
  border-bottom-width: 0;
  padding-bottom: 3px;
}

.k-button-group .k-button:active, .k-button-group .k-button.k-state-active, .k-button-group .k-button.k-state-selected, .k-button-group > input[type=radio]:checked + .k-button, .k-button-group > input[type=checkbox]:checked + .k-button {
  border-color: #22c4c4;
  color: #ffffff;
  background-color: #22c4c4;
  background-image: linear-gradient(rgba(255, 138, 88, 0.2), rgba(255, 138, 88, 0));
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
}

.k-upload .k-dropzone {
  padding: 3px;
}

.k-upload .k-dropzone {
  background-color: transparent;
}

.k-button.k-upload-button {
  padding: 0;
}

ul.k-upload-files.k-reset.ng-star-inserted {
  background-color: whitesmoke;
}

.card-header + .card-body {
  padding: 0 1rem 0.5rem;
}

.form-control {
  height: calc(1.8rem + 1px);
  font-size: 14px;
}

.k-textbox,
textarea,
textarea.k-textarea {
  width: 100% !important;
  resize: none;
}

kendo-textbox.k-textbox .k-input,
.k-textbox.form-control {
  height: calc(1.8rem + 1px);
  padding: 0.5rem 0.75rem;
}

.btn-success {
  background-color: #00a8ff;
  border-color: #00a8ff;
}

.btn-primary {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.btn-danger {
  background-color: #f16a72;
  border-color: #f16a72;
}

.btn-morado {
  background-color: #AF85C4;
  color: white;
}

.btn-turquesa {
  background-color: #22c4aa;
  color: white;
}

.btn-verde-oscuro {
  background-color: #64a996;
  color: white;
}

::selection {
  background: #a1cff7;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: #a1cff7;
  /* Gecko Browsers */
}

a, a:hover {
  color: #22c4c4;
}

h3 {
  font-size: 15px;
  color: #000000;
  overflow: hidden;
  text-transform: uppercase;
  padding: 0 0 5px 0;
  border-bottom: solid 1px #000000;
  font-family: "Roboto", sans-serif;
}

textarea.k-textarea {
  width: 100% !important;
}

.modal-header .close {
  padding: 5px;
  outline: 0;
}

.modal-xxl {
  width: 1500px;
  max-width: 1500px;
}

.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
  background-color: rgba(34, 196, 196, 0.45);
}

.k-checkbox:checked {
  border-color: #22c4c4;
  background-color: #22c4c4;
}

.k-checkbox:indeterminate, .k-checkbox.k-state-indeterminate {
  color: #22c4c4;
}

kendo-textbox.k-textbox {
  width: 100%;
  height: auto;
}

kendo-textbox.k-textbox .k-input {
  padding: 0.5rem 0.75rem;
  height: 2.25rem;
}

.k-button,
.k-button:hover {
  background-image: none;
}

.k-numerictextbox {
  width: auto !important;
}

.k-numerictextbox .k-input {
  text-align: right;
}

.form-group {
  margin-bottom: 5px;
}

.nolabel {
  margin-top: 25px;
}

kendo-textbox.k-textbox .k-input,
.k-textbox.form-control {
  height: calc(1.8rem + 1px);
  padding: 0.5rem 0.75rem;
}

.k-button-group .k-button:hover,
.k-button-group .k-button.k-state-hover,
.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active {
  z-index: 0;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #22c4c4;
}

.k-list .k-item.k-state-selected:hover,
.k-list-optionlabel.k-state-selected:hover {
  background-color: #22c4c4;
  opacity: 0.9;
}

.k-calendar .k-state-selected .k-link {
  background-color: #22c4c4;
}

.k-calendar .k-state-selected:hover .k-link,
.k-calendar .k-state-selected.k-state-hover .k-link {
  background-color: #22c4c4;
  opacity: 0.9;
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: #22c4c4;
}

.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-state-hover {
  color: #22c4c4;
}

.k-widget.form-control {
  padding: 0;
  border: 0;
}

.form-control {
  height: calc(1.8rem + 1px);
  font-size: 14px;
}

.k-dialog-buttongroup {
  justify-content: center;
}

.k-button.k-upload-button {
  padding: 0px 6px;
}

.k-textarea {
  width: 100%;
}

kendo-dropdownlist.k-widget.k-dropdown,
.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  background-image: none;
  background: transparent;
}

.k-dropdown, .k-dropdowntree {
  width: 100%;
}

.k-grid tbody td {
  white-space: nowrap;
  line-height: 10px;
  padding: 8px 12px;
}

.k-grid .k-grid-content td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invalid-dropdown > span, .k-textbox.k-invalid.is-invalid,
.k-textbox.k-state-invalid.is-invalid,
.k-textbox.ng-invalid.is-invalid,
.k-textbox.ng-invalid.ng-dirty.is-invalid,
.k-combobox.k-state-invalid.is-invalid .k-dropdown-wrap,
.k-combobox.ng-invalid.is-invalid .k-dropdown-wrap,
.k-combobox.ng-invalid.ng-dirty.is-invalid .k-dropdown-wrap,
.k-combobox.is-invalid .k-dropdown-wrap,
.k-textbox.is-invalid .k-input, .invalid-multiselect > div,
.k-numerictextbox.is-invalid,
.caja-geometria.is-invalid,
.k-dropdown.is-invalid {
  border: solid 1px #D33737 !important;
}

.alert {
  position: fixed;
  z-index: 99999;
  width: 100%;
  max-width: 1078px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
}

.sin-boton-vaciar .k-clear-value {
  /*Hace que no se muestre en los combos con esta clase la x para vaciarlos*/
  display: none;
}

/*orokorrak amaiera*/
/*Datos forja*/
h4.subtitulo {
  font-size: 15px;
}

.ng-trigger .card {
  background-color: #F3F4F6;
}

.caja-dentro-tab {
  margin-bottom: 5px;
  background-color: #ffffff;
  padding: 5px;
}

.caja-dentro-tab:after {
  display: block;
  clear: both;
  content: "";
}

.caja-dentro-tab i {
  margin-left: 10px;
}

.caja-dentro-tab .izq {
  float: left;
  width: calc(100% - 125px);
}

.caja-dentro-tab .dcha {
  float: left;
  margin-left: 10px;
  width: 115px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.caja-dentro-tab .dcha img {
  max-height: 83px;
}

label.descripcionInput {
  color: #00a8ff;
  font-size: 10px;
}

.caja-geometria {
  width: auto;
  position: absolute;
  background-color: white;
}

.caja-geometria .k-numerictextbox {
  width: 115px !important;
}

textarea.en-formulario {
  resize: none;
  height: 35px;
}

div.contendor-textarea-formulario {
  max-width: 950px;
  width: 100%;
}

.geometria-destacada .k-numeric-wrap input {
  background-color: #ee8625 !important;
  color: white !important;
}

.caja-dentro-tab .caja.grupodetres .cajaNumero {
  float: left;
  width: calc(33.333% - 15px);
}

.buscador-forja {
  width: 290px;
}

.caja-dentro-tab .cajaNumero {
  float: left;
  width: calc(50% - 10px);
}

.caja-dentro-tab .cajaX {
  float: left;
  width: 20px;
  text-align: center;
}

.caja-dentro-tab kendo-textbox.k-textbox {
  margin: 5px 0 0 0;
}

/*Datos forja amaiera*/
/*Configuración*/
.caja-imagen {
  margin-bottom: 5px;
  background-color: #ffffff;
  padding: 5px;
}

.caja-imagen:after {
  display: block;
  clear: both;
  content: "";
}

.caja-imagen .cont-izq {
  float: left;
  width: calc(100% - 115px);
}

.caja-imagen img.img-conf {
  float: left;
  width: 113px;
  margin-left: 2px;
  margin-top: 25px;
}

/*Configuración amaiera*/
/*manuales*/
.cont-archivo {
  border: 1px solid #ced4da;
  padding: 8px 8px 10px;
  background-color: whitesmoke;
}

.cont-archivo-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cont-archivo-inner span.k-file-group.k-icon {
  font-size: 32px;
  color: #666666;
}

button.k-button.k-button-icon.k-flat.k-upload-action.ng-star-inserted {
  float: right;
}

.caja-manuales.ng-star-inserted {
  display: block;
  float: left;
  width: 145px;
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.caja-manuales.ng-star-inserted span.ng-star-inserted {
  display: block;
  font-size: 45px;
  color: #dee2e6;
  border: solid 2px #dee2e6;
  padding: 15px 0;
}

.caja-manuales.ng-star-inserted label {
  display: block;
  line-break: anywhere;
  height: 50px;
}

/*manuales amaiera*/
/*Hoja de impresion*/
table#fichaImprimir {
  font-size: 13px;
}

table.no-vertical {
  width: 1000px;
}

th.header-negrita.destacado {
  background: #424242;
  color: #ffffff;
}

table.table-vertical td, table.no-vertical td {
  border: 3px solid #F7F7F5;
  background-color: white;
  text-align: center;
}

tr.celdas-destacadas td {
  background-color: #686868 !important;
  color: #ffffff !important;
}

table.table-vertical td.celda-negrita, table.no-vertical td.celda-negrita {
  font-weight: bold !important;
}

table.table-vertical th, table.no-vertical th {
  background-color: #E2E2E2;
  border: 3px solid white;
  font-weight: normal !important;
  text-align: center;
}

table.table-vertical th.header-negrita, table.no-vertical th.header-negrita {
  font-weight: bold !important;
}

.vertical span {
  transform: rotate(90deg);
  text-align: center;
  font-size: 33px;
  display: block;
  margin: 0px 0 0 -100px;
  white-space: nowrap;
  height: 55px;
  width: 250px;
}

.tabla-doble-linea td, .tabla-doble-linea th {
  padding: 1em;
}

.celdas-estrechas th, .celdas-estrechas td {
  border-width: 1px !important;
}

/*Hoja de impresion amaiera*/
/*login*/
.login-container {
  margin: 0 50px 0 0;
}

.menu-zabalduta .login-container {
  margin: 0 250px 0 0;
}

.card.login .card-header {
  text-align: center;
}

.card.login button {
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.login-logo {
  text-align: center;
}

.bg-login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(https://sgl.cafmiira.com/sgl/App_Themes/CAF_MiiRA_A_R/img/bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff;
}

.card.login {
  width: 275px;
  margin: 125px auto 0;
  background-color: rgba(241, 241, 241, 0.39);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding-top: 25px;
}

/*login amaiera*/
/*loader*/
.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
  border-color: #22c4c4;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}

.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #22C4C4;
  z-index: 4;
}

/*loader amaiera*/
/*botoiak*/
.btn {
  border-radius: 0;
  padding: 3px 8px;
  font-size: 13px;
}

.btn:hover {
  opacity: 0.8;
}

.btn-success {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.btn-outline-success {
  color: #22c4c4;
  border-color: #22c4c4;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  background-color: transparent;
  border-color: #22c4c4;
}

.btn-outline-success:hover {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
  background-color: #22c4c4;
  border-color: #22c4c4;
}

.btn-primary {
  background-color: #00a8ff;
  border-color: #00a8ff;
}

.btn-outline-primary {
  color: #00a8ff;
  border-color: #00a8ff;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #00a8ff;
  border-color: #00a8ff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: #00a8ff;
  border-color: #00a8ff;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger {
  background-color: #f16a72;
  border-color: #f16a72;
}

.btn-excel {
  background-color: #28a745;
  border-color: #28a745;
  color: #ffffff;
}

.btn-excel.focus,
.btn-excel:focus,
.btn-excel:hover {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-verde {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}

.btn-outline-verde {
  color: #4caf50;
  border-color: #4caf50;
}

.btn-outline-verde.focus,
.btn-outline-verde:focus {
  background-color: transparent;
  border-color: #4caf50;
}

.btn-outline-verde:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-verde.focus,
.btn-verde:focus,
.btn-verde:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}

.btn-azul {
  background-color: #ff6347;
  border-color: #ff6347;
  color: #ffffff;
}

.btn-outline-azul {
  color: #ff6347;
  border-color: #ff6347;
}

.btn-outline-azul.focus,
.btn-outline-azul:focus {
  background-color: transparent;
  border-color: #ff6347;
}

.btn-outline-azul:hover {
  background-color: #ff6347;
  border-color: #ff6347;
}

.btn-azul.focus,
.btn-azul:focus,
.btn-azul:hover {
  background-color: #ff6347;
  border-color: #ff6347;
  color: #ffffff;
}

.btn-amarillo {
  background-color: #FDAB3D;
  border-color: #FDAB3D;
  color: #ffffff;
}

.btn-outline-amarillo {
  color: #FDAB3D;
  border-color: #FDAB3D;
}

.btn-outline-amarillo.focus,
.btn-outline-amarillo:focus {
  background-color: transparent;
  border-color: #FDAB3D;
}

.btn-outline-amarillo:hover {
  background-color: #FDAB3D;
  border-color: #FDAB3D;
}

.btn-amarillo.focus,
.btn-amarillo:focus,
.btn-amarillo:hover {
  background-color: #FDAB3D;
  border-color: #FDAB3D;
  color: #ffffff;
}

.btn-naranja {
  background-color: #ee8625;
  border-color: #ee8625;
}

.btn-outline-naranja {
  color: #ee8625;
  border-color: #ee8625;
}

.btn-outline-naranja.focus,
.btn-outline-naranja:focus {
  background-color: transparent;
  border-color: #ee8625;
}

.btn-outline-naranja:hover {
  background-color: #ee8625;
  border-color: #ee8625;
}

.btn-naranja.focus,
.btn-naranja:focus,
.btn-naranja:hover {
  background-color: #ee8625;
  border-color: #ee8625;
  color: #ffffff;
}

.btn-morado {
  background-color: #AF85C4;
  border-color: #AF85C4;
  color: #ffffff;
}

.btn-outline-morado {
  color: #AF85C4;
  border-color: #AF85C4;
}

.btn-outline-morado.focus,
.btn-outline-morado:focus {
  background-color: transparent;
  border-color: #AF85C4;
}

.btn-outline-morado:hover {
  background-color: #AF85C4;
  border-color: #AF85C4;
}

.btn-morado.focus,
.btn-morado:focus,
.btn-morado:hover {
  background-color: #AF85C4;
  border-color: #AF85C4;
  color: #ffffff;
}

.k-button-primary, .k-button.k-primary {
  border-color: #22c4c4;
  color: #ffffff;
  background-color: #22c4c4;
}

.btn-editar {
  background-color: #8bc34a;
  color: #ffffff;
}

.btn-nuevo {
  background-color: #00a8ff;
  color: #ffffff;
}

.btn-eliminar {
  background-color: #f16a72;
  color: #ffffff;
}

.btn-copiar {
  background-color: #22c4c4;
  color: #ffffff;
}

/*botoiak amaiera*/
/*scroll bereziak*/
* {
  scrollbar-color: #202d39 #7c7c7c;
  scrollbar-width: thin;
  /*firefox*/
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  height: 8px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cdcdcd;
}

*::-webkit-scrollbar {
  width: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #253341;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*scroll bereziak bukaera*/
/*tabs*/
.k-tabstrip-items .k-item,
a, a:hover {
  color: #22c4c4;
}

/*tabs amaiera*/
/*switch*/
.k-switch-on:hover .k-switch-container, .k-switch-on.k-state-hover .k-switch-container {
  color: #ffffff;
  background-color: #22c4c4;
}

.k-switch-on .k-switch-container {
  color: #ffffff;
  background-color: #22c4c4;
}

.k-switch-container {
  width: 4em;
}

.k-switch-handle {
  width: 2em;
  height: 2em;
}

.k-switch {
  border-radius: 4em;
  width: 4em;
  margin-right: 5px;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 2em);
}

.k-switch-label-on, .k-switch-label-off {
  max-width: 100%;
}

.k-switch-label-on {
  left: 3px;
}

.k-switch-label-off {
  right: 3px;
}

/*switch amaiera*/
.barratop {
  transition: 0.3s ease all;
  background-color: #253341;
  left: 60px;
  border-radius: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 56px;
  padding: 0;
  z-index: 5;
}

.barratop h2 {
  margin: 0 0 0 20px;
  color: #f7f7f7;
  float: left;
  font-size: 20px;
}

/*MENU LATERAL*/
.barramenu {
  background-color: #2a3f54;
  width: 60px;
  padding: 0 0 40px 0;
  flex-flow: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  z-index: 5;
}

.barramenu .logotipoa {
  margin: 8px 0 10px 5px;
  height: 40px;
  width: 50px;
  float: none;
  background-image: url(./assets/img/logo.png);
  background-repeat: no-repeat;
  transition: 0.3s ease all;
  background-size: 128px;
  background-position: -84px 0px;
}

.barramenu .nav-item .nav-link {
  color: #CAD3DD;
}

.barramenu .nav-item.link-active a.nav-link {
  color: #22c4c4;
  /*background-color: rgba(30,30,30,0.35);*/
}

.barramenu h3 {
  display: none;
  overflow: hidden;
  clear: both;
  background-color: transparent;
  color: #fff;
  margin: 15px 12px 15px 11px;
  text-transform: inherit;
  color: #CAD3DD;
  font-weight: 300;
}

.barramenu a {
  visibility: hidden;
  width: 230px;
  color: #CAD3DD;
  font-size: 13px;
}

.barramenu .beheko-minimenu a.conf:before, .barramenu > .nav > li > a:before {
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  display: inline-block;
  font-family: Font Awesome\ 5 Free;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  font-size: 20px !important;
  margin-right: 15px;
  transition: all 0.5s;
}

.barramenu.menu-zabalduta ul.beheko-minimenu, .barramenu:hover ul.beheko-minimenu, ul.beheko-minimenu li {
  width: 230px;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #22c4c4;
  color: #22c4c4 !important;
}

ul.nav {
  margin: 47px 0 0 0;
  width: 100%;
}

ul.nav li.nav-item {
  width: 100%;
}

/*toggle despligue*/
.barramenu .menu-zabalera-blokeatu::before {
  width: 26px;
  opacity: 0.99;
  display: inline-block;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 15px !important;
  color: #c1cdd9;
  margin: -1px 0 0 -3px !important;
  padding: 0 !important;
}

.barramenu .menu-zabalera-blokeatu {
  display: none;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  right: 0;
  position: absolute;
  margin: 10px 10px 0 0;
  padding: 0px 0 0 5px !important;
  border: solid 3px #c1cdd9;
  top: 0;
  cursor: pointer;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu,
.barramenu:hover .menu-zabalera-blokeatu {
  display: block;
  line-height: 19px;
}

.barramenu.menu-zabalduta .menu-zabalera-blokeatu::before {
  content: "";
}

/*toggle despliegue amaiera*/
/*dropdown menu*/
.barramenu a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta a.nav-link.dropdown-toggle::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  content: "";
  color: #fff;
  float: right;
  margin-top: 5px;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  border-right-width: 0;
  border-top-width: 0;
}

.barramenu:hover .nav-item.show a.nav-link.dropdown-toggle::after,
.barramenu.menu-zabalduta .nav-item.show a.nav-link.dropdown-toggle::after {
  transform: rotate(135deg);
}

.show a.nav-link.dropdown-toggle {
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu .dropdown-menu {
  position: relative !important;
  background-color: transparent;
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  transform: translate3d(0px, 0px, 0px) !important;
  float: none !important;
}

.dropdown-item {
  padding: 5px 15px;
  width: 100%;
  display: block;
}

.barramenu .dropdown-menu.show {
  display: none !important;
}

.barramenu:hover .dropdown-menu.show, .barramenu.menu-zabalduta .dropdown-menu.show {
  display: block !important;
}

/*dropdown menu*/
/*barramenu zabalduta*/
.barramenu:hover, .barramenu.menu-zabalduta {
  width: 230px;
}

.barramenu.menu-zabalduta .logotipoa, .barramenu:hover .logotipoa {
  width: 160px;
  border-radius: 0;
  background-position: 0px;
  background-size: contain;
}

.barramenu.menu-zabalduta ul.nav, .barramenu:hover ul.nav {
  margin: 0;
}

.barramenu:hover h3, .barramenu.menu-zabalduta h3 {
  display: block;
}

.barramenu:hover a, .barramenu.menu-zabalduta a {
  visibility: visible;
}

.barratop.menu-zabalduta {
  left: 230px;
}

.contenedor.body-content.menu-zabalduta {
  margin: 60px 20px 0 240px;
}

.contenedor.body-content {
  margin: 65px 20px 0 70px;
  transition: 0.3s ease all;
  min-height: calc(100% - 110px);
}

.barramenu .nav > .show > a,
.barramenu .nav > .show > a:hover,
.barramenu .nav > .show > a:focus {
  border-left-color: transparent;
  background-color: rgba(30, 30, 30, 0.35);
}

.barramenu:hover li.dropdown.show > a::after,
.barramenu.menu-zabalduta li.dropdown.show > a::after {
  transform: rotate(135deg);
}

.barramenu:hover li.dropdown > a::after,
.barramenu.menu-zabalduta li.dropdown > a::after {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #fff;
  border-left: solid 3px #fff;
  transform: rotate(-45deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  margin-top: 10px;
}

.barramenu ul.dropdown-menu.show {
  background-color: rgba(30, 30, 30, 0.35);
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.5);
  border-right: solid 4px #24ab95;
  margin: 0;
}

ul.dropdown-menu a.nav-link {
  padding: 4px 0 0 28px;
}

.barramenu ul.dropdown-menu li::before {
  content: "";
  left: 10px;
  margin-top: 6px;
  position: absolute;
  width: 26px;
  opacity: 0.99;
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  width: auto;
  font-size: 10px !important;
  margin-right: 15px;
  transition: all 0.5s;
  color: white;
}

li.dropdown.show > a {
  background: #222a31 !important;
}

.barramenu > .nav > li > a.home-nav:before {
  content: "";
}

.barramenu > .nav > li > a.usuarios-nav:before {
  content: "";
}

.barramenu > .nav > li > a.datos-forja-nav:before {
  content: "";
}

.barramenu > .nav > li > a.tablas-maestras-nav:before {
  content: "";
}

.barramenu > .nav > li > a.configuracion-nav:before {
  content: "";
}

.barramenu > .nav > li > a.buscador-nav:before {
  content: "";
}

.barramenu > .nav > li > a.manuales-nav:before {
  content: "";
}

.barramenu > .nav > li > a.catalogo-forja-ruedas-nav:before {
  content: "";
}

/*barramenu zabalduta amaiera*/
.beheko-minimenu {
  position: fixed;
  bottom: 0px;
  background-color: #253341;
  margin: 0;
  width: 60px;
  cursor: pointer;
  z-index: 9999;
  transition: 0.3s ease all;
  padding: 0;
}

ul.beheko-minimenu li {
  width: 230px;
}

.barramenu:hover ul.beheko-minimenu,
.barramenu.menu-zabalduta ul.beheko-minimenu {
  width: 230px;
}

.beheko-minimenu a {
  padding: 10px;
}

.beheko-minimenu a:hover {
  color: #22c4c4;
}

.conf-menu {
  display: none;
}

.conf-menu.agertu {
  display: block;
}

.barramenu:hover h3.titulo-menu-configuracion, .barramenu.menu-zabalduta h3.titulo-menu-configuracion {
  display: none;
}

.barramenu:hover h3.titulo-menu-configuracion.agertu, .barramenu.menu-zabalduta h3.titulo-menu-configuracion.agertu {
  display: block;
}

.barramenu .beheko-minimenu a:before {
  color: #73879C;
  font-size: 28px !important;
}

.barramenu .beheko-minimenu a.conf:before {
  content: "";
}

ul.beheko-minimenu li {
  list-style-type: none;
}

/*MENU LATERAL AMAIERA*/
/*USERMENU*/
.usermenu {
  margin-right: 8px;
}

.usermenu > li {
  cursor: pointer;
  margin: 0px 1px;
  padding: 0px 6px;
  transition: background-color 0.5s;
}

.usermenu > li:hover {
  border-radius: 5px;
}

.usermenu-iso > li {
  cursor: pointer;
}

.usermenu > li .dropdown-menu {
  top: 110%;
}

.usermenu li a.nombre-usuario {
  background: none !important;
  line-height: 41px;
}

.listaLenguajeItem {
  padding-left: 10px !important;
  transition: background-color 0.5s;
}

.listaLenguajeItem:hover {
  background-color: #d5e3f3;
}

.listaLenguajeItemSeleccionado {
  padding-left: 10px !important;
  background-color: #acc5df;
}

.circulo-tutorial {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #e1e1e1;
  color: #253341;
  text-align: center;
  line-height: 32px;
  font-weight: 900;
}

.tutoriales-nav::before {
  opacity: 0.99;
  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  text-rendering: auto;
  visibility: visible;
  font-size: 30px !important;
  color: #cad3dd;
  content: "";
}

.circulo-usuario {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-block;
  background-color: #22c4c4;
  color: #fff;
  text-align: center;
  line-height: 32px;
}

.dropdown-menu.dropdown-usuario {
  padding: 0;
}

.inner-usuario-nombre-cont {
  background-color: #99bcb1;
  width: 300px;
  padding: 15px;
  color: #ffffff;
}

span.inner-usuario-circulo-usuario {
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: inline-block;
  background-color: #22c4c4;
  color: #fff;
  text-align: center;
  line-height: 34px;
  margin: 0 10px 0 0;
}

.dropdown-menu.dropdown-usuario.show {
  right: 0;
  left: auto;
}

.inner-usuario-tipo-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-right: solid 1px #99bcb1;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-num-cont {
  float: left;
  width: 50%;
  text-align: center;
  padding: 10px;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-itxura-cont {
  width: 50%;
  float: left;
  border-bottom: solid 1px #99bcb1;
}

.inner-usuario-titulo {
  display: block;
  color: #9e9e9e;
  font-size: 13px;
  text-align: center;
}

.itxura-aldaketa-argia {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #ffffff 0%, #e6e6e6 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

.itxura-aldaketa-iluna {
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 33px;
  height: 33px;
  transition: all 1s;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #253341 0%, #2a3f54 50%, #00c1a0 50%, #00c1a0 100%);
  border: solid 2px #2a3f54;
}

a#Logout {
  background-color: #2a3f54;
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #ffffff;
  text-align: center;
  width: 90%;
  transition: all 1s;
}

li.selector-idioma > .idioma-seleccionado {
  background-color: #5cc6fd;
  padding: 5px 8px;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  margin: 4px 0px 0px 0px;
}

.selector-idioma ul.dropdown-menu {
  min-width: 50px;
}

/*USERMENU AMAIERA*/
.card {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  border-width: 0;
  margin: 0 0 20px 0;
}

.card-body {
  padding: 1rem;
}

.card-header + .card-body {
  padding: 0rem 1rem 1rem 1rem;
}

.card-header {
  background-color: transparent;
  border-bottom-width: 0;
  padding-bottom: 3px;
}

.plegarpanel {
  position: absolute;
  right: 25px;
  top: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  vertical-align: 0.255em;
  content: "";
  color: #fff;
  float: right;
  border-bottom: solid 3px #2a3f54;
  border-left: solid 3px #2a3f54;
  transform: rotate(135deg);
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  cursor: pointer;
}

.card.plegado {
  height: 40px;
  overflow: hidden;
}

.card.plegado .plegarpanel {
  transform: rotate(-45deg);
}

/*GRID*/
kendo-grid.k-widget.k-grid {
  border-width: 0;
  padding-bottom: 5px;
}

.k-grid-header,
kendo-grid-toolbar.k-grid-toolbar.k-header {
  background-color: transparent;
}

.k-grid td,
.k-grid th.k-header.k-filterable {
  border-width: 0;
  cursor: pointer;
}

.k-grid th.k-header.k-filterable span.k-link {
  font-weight: bold;
}

.k-grid-table-wrap tr {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-grid-table-wrap tr.k-alt {
  background-color: initial;
}

/*scroll bereziak grid*/
.k-grid-content::-webkit-scrollbar-track {
  background: #ffffff;
}

/*scroll bereziak grid amaiera*/
/*GRID amaiera*/
/*solo móvil*/
@media (max-width: 768px) {
  .navbar-toggler-icon {
    color: #f7f7f7;
  }

  #usermenu {
    position: absolute;
    right: 50px;
    width: 145px;
    top: 0;
  }

  #usermenu ul.navbar-nav.navbar-nav-user.usermenu.mt-2 {
    display: block;
    margin: 0 !important;
  }

  #usermenu ul.navbar-nav.navbar-nav-user.usermenu.mt-2 li {
    width: 46px;
    display: inline-block;
    vertical-align: top;
  }

  .barramenu {
    height: 56px;
    overflow: hidden;
  }

  .barramenu:hover, .barramenu.menu-zabalduta {
    width: 230px;
    height: 100vh;
    overflow-y: auto;
  }

  .menu-zabalera-blokeatu {
    display: none !important;
  }

  .contenedor.body-content {
    margin: 70px 0 0 0 !important;
    transition: 0.3s ease all;
  }

  #usermenu .dropdown-menu.dropdown-usuario.show {
    right: 0;
    left: auto;
    position: absolute;
  }
}
/*tablet*/
/*escritorio*/