* {
  /*font-family: 'Verdana', sans-serif;*/
}

div.machines-container {
  width: fit-content;
  user-select: none;
  height: 100%;
}

  div.machines-container > div.machine {
    display: flex;
    white-space: nowrap;
    box-sizing: border-box;
    border-bottom: solid 1px #e5e5e5;
    padding-left: 7px;
    align-items: center;
  }

    div.machines-container > div.machine:first-of-type {
      margin-top: 1px;
      border-top: solid 1px #e5e5e5;
    }

.gantt-chart-planificadorcorto .machine div:first-child {
  background-color: #22c4c442;
  padding: 6px 5px;
  width: 45px;
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
  border: solid 1px #22c4c4;
}

div.machines-container > div.machine > div > img {
  width: 100%;
}

div.machines-container > div.machine > div > span {
  color: #333333;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
}

svg.gantt-chart {
  user-select: none;
  overflow: visible;
}

  svg.gantt-chart > g.y.grid > g.tick > line,
  svg.gantt-chart > g.x.grid > g.tick > line,
  svg.gantt-chart > g.x.grid > path.domain {
    color: #e5e5e5;
    stroke: #e5e5e5;
  }

  path.domain {
    stroke: #e5e5e5;
  }

  svg.gantt-chart > g.axis-bottom > g.tick > line {
    color: #e5e5e5;
    stroke: #e5e5e5;
    }

  svg.gantt-chart > g.task-container > g.not-allowed > rect {
    opacity: 0.20;
    cursor: no-drop;
  }

    svg.gantt-chart > g.task-container > g.not-allowed > rect:last-of-type {
      fill: #f44336;
    }

  svg.gantt-chart > g.task-container > g > rect:last-of-type {
    stroke: rgba(0,0,0,.3);
    stroke-width: .2;
  }

  /*  svg.gantt-chart > g.task-container > g > rect.resalt {
    stroke: #e91e63e6;
    stroke-width: 2px;
  }*/

  svg.gantt-chart > g.task-container > g {
    transition: opacity .2s;
  }

    svg.gantt-chart > g.task-container > g.fadeout {
      opacity: 0.20;
    }


    svg.gantt-chart > g.task-container > g > text {
      font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
      pointer-events: none;
      text-anchor: start;
      font-size: 14px;
      fill: white;
    }

  svg.gantt-chart > g.zoom-brush > g {
    font-size: 11px;
    pointer-events: none;
  }

  svg.gantt-chart > g.zoom-brush > image.handle--custom {
    cursor: ew-resize;
  }

  svg.gantt-chart > g.zoom-brush > rect.selection {
    cursor: grab;
  }

  svg.gantt-chart > g.zoom-brush > rect.overlay[cursor="move"] {
    cursor: grabbing;
  }

  svg.gantt-chart > g.zoom-brush > rect.bg {
    fill: #DFDFDF;
    stroke: #0000001a;
    stroke-width: 1px;
  }

  svg.gantt-chart > g.zoom-brush > rect.selection {
    stroke: none;
    fill: #EAEAEA;
    fill-opacity: 1;
  }

  svg.gantt-chart rect.zoom-rect {
    fill: #eaeaea7a;
    stroke: #00000085;
    stroke-width: 1px;
    stroke-dasharray: 9px;
  }

  svg.gantt-chart > g.show-all-button {
    cursor: pointer;
  }

    svg.gantt-chart > g.show-all-button:hover rect {
      opacity: 0.25;
    }

  /* TURNO DE NOCHE */
  svg.gantt-chart > g > g.delimiter.noche > rect {
    fill: rgb(255, 125, 0);
    stroke: rgb(255, 125, 0);
    fill-opacity: 0.1;
    stroke-opacity: 0.1;
  }

  svg.gantt-chart > g > g.delimiter.noche > line {
    stroke: #3e3e3e;
    stroke-width: 2;
  }
  /* TURNO DE TARDE */
  svg.gantt-chart > g > g.delimiter.tarde > rect {
    fill: rgb(119, 84, 243);
    stroke: rgb(119, 84, 243);
    fill-opacity: 0.1;
    stroke-opacity: 0.1;
  }

  svg.gantt-chart > g > g.delimiter.tarde > line {
    stroke: #3e3e3e;
    stroke-width: 2;
  }
  /* TURNO DE MAÑANA */
  svg.gantt-chart > g > g.delimiter.mañana > rect {
    fill: rgb(13, 239, 255);
    stroke: rgb(13, 239, 255);
    fill-opacity: 0.1;
    stroke-opacity: 0.1;
  }

  svg.gantt-chart > g > g.delimiter.mañana > line {
    stroke: #3e3e3e;
    stroke-width: 2;
  }

body div.tooltip-gantt {
  font-size: 11px;
  background-color: white;
  border: 3px solid #48328b;
  padding: 15px 5px 5px 5px;
  position: relative;
  z-index: 9;
}

body div.tooltip-gantt {
  z-index: 1501 !important;
}

.gantt-popup .gantt-chart-planificadorcorto {
  overflow: hidden;
}

body.iluna div.tooltip-gantt {
  background-color: #3b434e;
}

body div.tooltip-gantt > div.arrow {
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #f00;
}
body div.tooltip-gantt {
  pointer-events: none;
}

body div.tooltip-gantt.bottom-reverse > div.arrow {
  top: -20px;
  transform: rotate(180deg);
}

body div.tooltip-gantt > p {
  line-height: 0px;
  margin: 0;
}

  body div.tooltip-gantt > p ul {
    list-style: none;
    margin: 0px;
    padding-left: 13px;
    margin-bottom: 6px;
  }

span.tooltip-title {
  color: #22c4c4;
}
