$color-fondo: #323a46;
$color-card: #3b434e;
$color-texto: #dee2e6;




body.iluna {
  background-color: $color-fondo;
  color: $color-texto;
}

body.iluna .card {
  background-color: $color-card;
  color: $color-texto;
}
